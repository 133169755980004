import { Component } from '@angular/core';

@Component({
  selector: 'my-help',
  templateUrl: 'help.component.html',
  styleUrls: ['help.component.css']
})
export class amHelpComponent {
  show = 0;
}
